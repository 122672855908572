import React from 'react';
import styles from './header.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import InfoTooltip from 'commonComponents/infoTooltip';
import UserProfile from 'svgComponents/userProfile';
import UpArrow from 'svgComponents/upArrow';
import {checkIfPresent} from 'util/utilFunctions';
import { getFormattedCurrency } from 'util/currencyFormatter';
import ReportGraph from 'svgComponents/reportGraph';
import ChangeInMetric from 'commonComponents/changeInMetric';
import updateRoute from 'util/webAppRoutes/updateRoute';
import phaseRouteConstants from 'constants/phaseRouteConstants';
import urlConsts from 'constants/url';
import {getUserName, roundValue} from 'util/utilFunctions';
import routes from 'util/routes/routeConstants';
import WalkthroughComponent from 'commonComponents/walkthroughComponent';

class Header extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			clickedDropDown: -1,
			onHoverUserProfile: false,
			openUserProfile: false
		}
	}

	moveToKnolskapePage = () => {
		window.open(urlConsts.get('KNOLSKAPE_URL'),'_blank');
	}

	onClickQuarter = (eachRound) => {
		if(this.props.userState.get('isQuarterReportWalkthroughSeen')) {
			this.props.setUserState({
				isQuarterReportWalkthroughSeen: false
			})
		}
		if(eachRound.isRoundUnlocked && !eachRound.isReportAvailable) {
			this.props.setUserState({
				selectedRoundId: eachRound.id,
				showHeader: true
			});
			updateRoute ({
				route: phaseRouteConstants['observe'],
				params: {},
			});
		} else if (eachRound.isRoundUnlocked && eachRound.isReportAvailable) {
			this.props.setUIState({
				isEndReportFromFooter: true
			})
			this.props.setUserState({
				selectedRoundId: eachRound.id,
				showHeader: false,
				currentPhaseKey: 'endReport'
			});
			this.props.fetchLeaderboard();
			updateRoute ({
				route: phaseRouteConstants['endReport'],
				params: {},
			});
		}
	}

	onClickLogout = () => {
		this.setState({
			openUserProfile: false
		});
		window.location.href = urlConsts.get('LOG_OUT');
	}

	onClickOnboarding = () => {
		this.setState({
			openUserProfile: false
		});
		this.props.setUserState({
			showHeader: true
		});
		updateRoute({
			route: routes.get('INTRO'),
			params: {}
		});
	}

	toggleUserProfile = () => {
		this.setState({
            openUserProfile: !this.state.openUserProfile
        });
	}

	setOnHoverUserProfile = () => {
		this.setState({
			onHoverUserProfile: true
		});
	}

	unsetOnHoverUserProfile = () => {
		this.setState({
			onHoverUserProfile: false
		});
	}

	toggleDropDown = (clickedDropDown) => {
		this.props.setUIState({
			isDropDownOverlayOpen: !this.props.uiState.get('isDropDownOverlayOpen'),
			closePopovers: false
		});
		this.setState({
			clickedDropDown
		});
	}

	renderLogoContainer = (myStyles) => {
		return (
			<div styleName="logo-container">
				<div styleName="logo-image">
					<img alt="logo" src={this.props.getImage('IMG_DT_LOGO')} width="100%" height="100%" />
				</div>
			</div>
		);
	}

	renderUserProfile = (myStyles) => {
		const skin = this.props.skinGuide.get('globalProfiles').get('palette');
		return (
			<div
				styleName="user-profile-container-with-options"
				onMouseEnter={() => {this.setOnHoverUserProfile();}}
				onMouseLeave={() => {this.unsetOnHoverUserProfile();}}
				onClick={() => {
					this.toggleUserProfile();
				}}
				// tabIndex="0"
                // onBlur={() => {
                //     this.setState({
                //         openUserProfile: false
                //     })
                // }}
			>
				<div styleName="user-profile-container">
					<div styleName="user-profile-image-container">
						<UserProfile
							svgColor={this.state.onHoverUserProfile || this.state.openUserProfile ? skin.get('primaryColor') : skin.get('grayColor4')}
						/>
					</div>
					<div styleName="user-profile-arrow-container">
						<UpArrow
							svgColor={this.state.onHoverUserProfile || this.state.openUserProfile ? skin.get('primaryColor') : skin.get('grayColor4')}
						/>
					</div>
				</div>
				{
					this.state.openUserProfile
					?	<div className={css(myStyles.userProfileOptionContainer)} styleName="user-profile-option-container">
							<div styleName="user-name-container">
								<div className={css(myStyles.userName)} styleName="user-name">
									{this.props.getLabel('label_hello_username', "", {
										USER_NAME: getUserName(this.props.userState.toJS())
									})}
								</div>
							</div>
							<div
								className={css(myStyles.customOption)}
								styleName="each-option"
								onClick={() => {this.onClickOnboarding();}}
							>
								{this.props.getLabel('label_onboarding')}
							</div>
							<div
								className={css(myStyles.customOption)}
								styleName="each-option"
								onClick={() => {this.onClickLogout();}}
							>                
							  {(this.props.userState.get('logoutType') === 'normal' || this.props.userState.get('logoutType') === 'NORMAL') ? this.props.getLabel('label_logout') : this.props.getLabel('label_logout_return')}
							</div>
						</div>
					:	null
				}
				
			</div>
		);
	}

	renderQuarters = (myStyles) => {
		const skin = this.props.skinGuide.get('globalProfiles').get('palette');
		const roundList = this.props.rounds.get('roundList').toJS();
		const roundDivs = roundList.map((eachRound, index) => {
			return (
				<div
					styleName="single-quarter-container"
					className={eachRound.isRoundUnlocked ? css(myStyles.addCursor) : null}
					onClick={() => {
						this.onClickQuarter(eachRound);
					}}
				>
					{
						eachRound.id === this.props.userState.get('selectedRoundId')
						?	<div
								styleName="single-quarter-container-background"
								className={css(myStyles.quarterWithBorder)}
							></div>
						:	null
					}
					<div
						className={
							eachRound.id === this.props.userState.get('selectedRoundId')
							?	css(myStyles.quarterTextSelected)
							:	eachRound.isRoundUnlocked
								?	css(myStyles.quarterTextAvailable)
								:	css(myStyles.quarterTextNotAvailable)
						}
						styleName="quarter-text"
					>
						Q{eachRound.id}
					</div>
					{
						eachRound.isReportAvailable
						?	<div styleName="quarter-analytics">
								<ReportGraph
									svgColor={
										eachRound.id === this.props.userState.get('selectedRoundId')
										?	skin.get('primaryColor')
										:	eachRound.isRoundUnlocked
											?	skin.get('grayColor1')
											:	skin.get('grayColor5')
									}
								/>
								{
									index == 0 && this.props.userState.get('isQuarterReportWalkthroughSeen') ?
									<WalkthroughComponent
										arrowType="BOTTOM"
										propStyles={{
											top: '-66px',
											left: '-18px',
											width: '300px'
										}}
										text={this.props.getLabel('label_walkthrough_quarter_report')}
									/>
										: null
								}
							</div>
						:	null
					}
				</div>
			);
		});
		return (
			<div styleName="quarters-container"
				style={{
					pointerEvents: this.props.uiState.get('isUserOnIntroScreen') ? 'none' : 'all'
				}}
			>
				{roundDivs}
			</div>
		);
	}

	renderMetrics = (myStyles) => {
		const metrics = this.props.metrics.get('metrics').toJS();
		const defaultBudgetObj = {
			value: 0,
			diff: 0
		}
		const targetRevenue = checkIfPresent(this.props.storyline.get('baseValues').targetRevenue)
								?   this.props.storyline.get('baseValues').targetRevenue
								:   this.props.storyline.get('baseDefaultValues').get('targetRevenue');

		let budgetObj = metrics.find((eachMetric) => {
			return (eachMetric.key === 'revenue');
		});
		if(!checkIfPresent(budgetObj)) {
			budgetObj = defaultBudgetObj
		}


		const defaultCsObj = {
			value: 0,
			diff: 0
		}

		const targetCr = checkIfPresent(this.props.storyline.get('baseValues').targetCr)
								?   this.props.storyline.get('baseValues').targetCr
								:   this.props.storyline.get('baseDefaultValues').get('targetCr');

		let csObj = metrics.find((eachMetric) => {
			return (eachMetric.key === 'cr');
		});
		if(!checkIfPresent(csObj)) {
			csObj = defaultCsObj;
		}
		return (
			<div styleName="metrics-container">
				<div styleName="single-metric-container">
					<div styleName="metric-text-container">
						<div className={css(myStyles.metricText)} styleName="metric-text">
							{this.props.getLabel('label_revenue')}:&nbsp;
						</div>
						<InfoTooltip
							tooltipText={this.props.getLabel('label_revenue_tooltip')}
							alignTooltipKey="TOP"
							propStyles={{
								width: '12px',
								height: '12px'
							}}
						/>
					</div>
					<div className={css(myStyles.metricValue)} styleName="metric-value">
						{getFormattedCurrency(budgetObj.value, 'USD')}
						&nbsp;
						<span className={css(myStyles.metricValueTarget)}>
							({this.props.getLabel('label_target')}:&nbsp;{getFormattedCurrency(targetRevenue, 'USD')})
						</span>
						{
							!this.props.uiState.get('isUserOnIntroScreen')
							?	<ChangeInMetric
									metricValue={budgetObj.value}
									changeValue={roundValue(budgetObj.diff, 2)}
									styleObj={{
										bottom: '25px',
										left: '0'
									}}
									animateObj={{
										bottom: '0px'
									}}
									formatValue={true}
								/>
							:	null
						}
					</div>
				</div>
				<div styleName="single-metric-container">
					<div styleName="metric-text-container">
						<div className={css(myStyles.metricText)} styleName="metric-text">
							{this.props.getLabel('label_ce')}&nbsp;
						</div>
						<InfoTooltip
							tooltipText={this.props.getLabel('label_customer_satisfaction_tooltip')}
							alignTooltipKey="TOP"
							propStyles={{
								width: '12px',
								height: '12px'
							}}
						/>
					</div>
					<div className={css(myStyles.metricValue)} styleName="metric-value">
						{roundValue(csObj.value, 2)}
						&nbsp;
						<span className={css(myStyles.metricValueTarget)}>
							({this.props.getLabel('label_target')}:&nbsp;{targetCr})
						</span>
						{
							!this.props.uiState.get('isUserOnIntroScreen')
							?	<ChangeInMetric
									metricValue={csObj.value}
									changeValue={roundValue(csObj.diff, 2)}
									styleObj={{
										bottom: '25px',
										left: '5%'
									}}
									animateObj={{
										bottom: '0px'
									}}
								/>
							:	null
						}
					</div>
				</div>
			</div>
		);
	}

	renderKnolskapeBranding = (myStyles) => {
		return (
			<div styleName="knolskape-container">
				<div styleName="knolskape-image"
					onClick={() => {
						this.moveToKnolskapePage();
					}}
				>
					<img alt="logo" src={this.props.getImage('IMG_KNOLSKAPE_LOGO')} width="100%" height="100%" />
				</div>
			</div>
		);
	}

	render() {
		const { headerConfig } = this.props;
		if (!headerConfig.get('showHeader')) {
			return null;
		}
		const myStyles = getSkin(this.props.skinGuide);
		
		return (
			<div 
				className={css(myStyles.headerContainer)} 
				styleName="header-container"
			>
				<div styleName="logo-section">
					{this.renderLogoContainer(myStyles)}
				</div>
				<div styleName="other-section">
					{this.renderQuarters(myStyles)}
					{this.renderMetrics(myStyles)}
					{this.renderKnolskapeBranding(myStyles)}
					{this.renderUserProfile(myStyles)}
				</div>
			</div>
		);
	}
}

export default applyWrappers(Header, styles);
